.rating_main {
  .week_title {
    font-size: 3rem;
    margin-top: 7rem;
    border: 2px solid var(--deep-black);;
    border-radius: 9999px;
    background-color: #272727;
    padding: 0.625rem 3rem;
    text-align: center;
    color: #fff;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .week_rating {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 3rem;
    .week_card_ctn {
      background-color: var(--bg-white);
      padding: 2rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 35rem;
      color: var(--footer-text);
      .week_rating_card {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover .img_overlay {
          display: block!important;
        }
        .current_rank {
          display: flex;
          align-items: center;
          gap: 1rem;
          flex: 1;
          p {
            font-size: 3rem;
            font-weight: 600;
          }
        }
        .child_info {
          display: flex;
          gap: 1rem;
          flex: 3;
          .week_rating_img {
            width: 5rem;
            height: 5rem;
            overflow: hidden;
            display: flex;
            border-radius: 0.5rem;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .img_overlay {
              position: absolute;
              background: rgba(0, 0, 0, 0.2705882353);
              width: 100%;
              height: 100%;
              top: 0;
              display: none;
              transition: all 1s ease;
              a{
                width: 100%;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
        
                svg{
                  color: #fff;
                  font-size: 20px;
                }
              }
            }
          }
          section {
            display: flex;
            flex-direction: column;
            font-size: 1.5rem;
            justify-content: space-between;
            .week_rating_name{
              color: var(--text-1);
            }
            .week_rating_artists{
              color: var(--text-2);
            }
          }
        }
        .total_time {
          font-size: 1.3rem;
        }
      }
      h1 {
        color: var(--footer-text);
        font-size: 2.5rem;
        text-align: center;
        padding-bottom: 5px;
      }
      .show_all {
        width: 150px;
        height: 45px;
        border-radius: 50px;
        color: var(--white-color);;
        background-image: linear-gradient(180deg, #7ec9f5, #97b8f7);
        box-shadow: 0 6px 10px -2px rgba(97, 163, 238, 0.5);
        outline: none;
        cursor: pointer;
        border: none;
        margin: 13px auto 0;
        font-size: 16px;
        line-height: 45px;
        text-align: center;
        transition: all 0.1s ease-in-out;

        &:hover {
          transform: translateY(3px);
          box-shadow: none;
          color: var(--deep-black);;
        }
        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .rating_main {
    .week_rating {
      overflow-x: auto;
      width: 100%;
    }
  }
}
