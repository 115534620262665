.col_3_layout_Container {
    display: flex;
    gap: 30px;

    .col_3_layout {
        width: 33.333333%;

        .col_3_layout_colum_item {
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 10px 10px;
            background: var(--bg-white);
            margin: 10px 0;
            color: var(--footer-text);
        border-radius: 5px;

            &:hover {
                background-color:var(--bg-white-1);
                cursor: pointer;
            }

            &:hover .img_overlay {
                display: flex !important;
            }


            .playlist_item_img {
                width: 60px;
                height: 60px;
                overflow: hidden;
                border-radius: 5px;
                position: relative;

                img {
                    width: 100%;
                }

                .img_overlay {
                    position: absolute;
                    background: #00000045;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    transition: all 1s ease;

                    .img_overlay_group_btn {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 40px;

                        svg {
                            font-size: 20px;
                            color: #fff;
                        }

                        svg.play_icon {
                            font-size: 40px;
                        }
                    }
                }
            }

            .playlist_item_content {
                font-family: sans-serif;

                .content_name {
                    font-size: 16px;
                    font-weight: 600;
                }

                .content_cate {
                    margin-top: 5px;
                    font-size: 14px;
                    opacity: .7;
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .col_3_layout_Container{
        overflow-x: auto;
        .col_3_layout{
            width: auto !important;
            .col_3_layout_colum{
                width: 30rem;
            }
        }
    }
}