* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;  
  background-color: var(--background-color);
  color: var(--white-color);
  font-family: "Roboto", "Arial", sans-serif;
  overflow: hidden;
}

html {
  font-size: 62.5%;
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border-radius: 8px;
  border: unset;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
  color: #000;
}

.main_content {
  height: 88vh;
}


/* player */
.music-player-panel.translate {
  background-color: var(--bg-player) !important;
}

.react-jinke-music-player-main .music-player-panel {
  color: var(--item-player) !important;
}
.react-jinke-music-player-main.dark-theme .rc-slider-rail{
  background-color: var(--line-player)!important;
}

.react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-handle,
.react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-track {
  background-color: var(--main-color) !important;
}

.react-jinke-music-player-main svg:active,
.react-jinke-music-player-main svg:hover {
  color: #257eff !important;
}

.rc-slider-step {
  background: #0000001c !important;
}

.react-jinke-music-player .music-player-controller {
  color: #257eff !important;
}

.react-jinke-music-player .music-player-controller .music-player-controller-setting {
  background: rgba(0, 68, 255, 0.3) !important;
}

.group.loading-icon svg {
  fill: var(--main-color);
}

.react-jinke-music-player {
  right: 20px !important;
  left: unset !important;
}

:root {

  --lightmain-color: #257eff;
  --lightwhite-color: #fff;
  --lightfooter-text: #333;
  --light-text-1: #000;
  --light-text-2: #868585;
  --lightbg-white: #ebf1ff;
  --lightbg-white-1: #bcd0fe;
  --lightdeep-black: #000;
  --lightbackground-color: linear-gradient(180deg,
      rgb(191, 215, 250) 0%,
      rgba(235, 241, 255, 1) 42%,
      rgba(255, 255, 255, 1) 100%);
  --lightrightsidebar-light: #ebf1ff;
  --lightrightsidebar-item: rgba(255, 255, 255, 0.3);
  --likerightsidebar-hover: rgba(60, 117, 255, 0.34);
  --light-bg-radio: #afc7ff;
  --light-stroke: #001062;
  --light-bg-header: #bfd7fa;
  --light-bg-player: #fff;
  --light-item-player: #424242;
  --light-line-player: #fff;

  /* --background-color-dark: linear-gradient(180deg, 
  rgb(27, 27, 27) 0%, 
  rgb(86, 86, 86) 42%, 
  rgb(130, 130, 130) 100%);
  --rightsidebar-dark: #7d818a; */
  /* Thêm các biến màu cho text, borders, v.v ... cho cả hai chủ đề */
  --main-color-dark: #fff;
  --white-color-dark: #000;
  --footer-text-dark: #ccc;
  --dark-text-1: #fff;
  --dark-text-2: #a6b3c4;

  /* Màu trung gian sáng hơn đối ngược của #333 */
  --bg-white-dark: #102037;
  --bg-white-1-dark: #2f3e62;
  --deep-black-dark: #dddddd;
  /* Màu tối hơn và đậm hơn đối ngược của light blue */
  --background-color-dark: #162a45;
  --rightsidebar-dark: #21344e;
  /* Tương tự như nền tối */
  --darkrightsidebar-item: rgba(255, 255, 255, 0.2);
  --darkrightsidebar-hover: rgb(88 118 188 / 34%);
  --dark-bg-radio: #3b68ef;
  --dark-stroke: #ffffff;
  --dark-bg-header: #162a45;
  --dark-bg-player: #172f4f;
  --dark-item-player: #fff;
  --dark-line-player: #5d6d84;


}

[data-theme="dark"] {
  /* Set lại các biến cho dark theme */
  --rigthsidebar-bg: var(--rightsidebar-dark);
  --main-color: var(--main-color-dark);
  --white-color: var(--white-color-dark);
  --footer-text: var(--footer-text-dark);
  --bg-white: var(--bg-white-dark);
  --bg-white-1: var(--bg-white-1-dark);
  --deep-black: var(--deep-black-dark);
  --background-color: var(--background-color-dark);
  --trightsidebar-light: var(--rightsidebar-dark);
  --rightsidebar-item-active: var(--darkrightsidebar-item);
  --rightsidebar-hover: var(--darkrightsidebar-hover);
  --bg-radio: var(--dark-bg-radio);
  --color-stroke: var(--dark-stroke);
  --bg-header: var(--dark-bg-header);
  --bg-player: var(--dark-bg-player);
  --item-player: var(--dark-item-player);
  --text-1: var(--dark-text-1);
  --text-2: var(--dark-text-2);
  --line-player: var(--dark-line-player);
}

[data-theme="light"] {
  /* Set lại các biến cho light theme */
  --rigthsidebar-bg: var(--lightrightsidebar-light);
  --main-color: var(--lightmain-color);
  --white-color: var(--lightwhite-color);
  --footer-text: var(--lightfooter-text);
  --bg-white: var(--lightbg-white);
  --bg-white-1: var(--lightbg-white-1);
  --deep-black: var(--lightdeep-black);
  --background-color: var(--lightbackground-color);
  --trightsidebar-light: var(--lightrightsidebar-light);
  --rightsidebar-item-active: var(--lightrightsidebar-item);
  --rightsidebar-hover: var(--likerightsidebar-hover);
  --bg-radio: var(--light-bg-radio);
  --color-stroke: var(--light-stroke);
  --bg-header: var(--light-bg-header);
  --bg-player: var(--light-bg-player);
  --item-player: var(--light-item-player);
  --text-1: var(--light-text-1);
  --text-2: var(--light-text-2);
  --line-player: var(--light-line-player);


}




/* scrollbar  */
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 5px;
  height: 4px;
  background-color: var(--background-color) !important;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #257cff81;
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}