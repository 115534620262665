.Header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 72px;
  background-color: var(--bg-header);
  padding: 0 60px;
  z-index: 9;
  box-shadow: rgba(46, 91, 240, 0.4) 0px 5px, rgba(46, 114, 240, 0.3) 0px 10px,
    rgba(46, 91, 240, 0.2) 0px 15px, rgba(46, 124, 240, 0.1) 0px 20px,
    rgba(46, 146, 240, 0.05) 0px 25px;

  .header_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .avt_page {
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    border-radius: 50%;
  }

  .avt_page img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .header_search {
    position: relative;
    width: 50%;

    .input__search {
      width: 100%;
      padding: 12px 18px;
      font-size: 16px;
      font-family: inherit;
      box-shadow: 0 0 0 1px #b9ceff;
      border: none;
      border-radius: 25px;
      background-color: var(--bg-white);
      transition: all 0.3s;
      // color: #3e3e3e;
      color: var(--deep-black);
    }


    .input__search::placeholder {
      color: #82b0fb;
      font-size: 14px;
    }

    .input__search:focus {
      outline: none;
      box-shadow: 0 0 10px 1px #346ffa;
      border: none;
      transition: all 0.3s;
    }

    .search_btn{
      background-color: transparent ;
      color: #82b0fb;
      font-size: 2rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    .search_btn:hover{
      color: #498dfa
    }
    .result_box{
      position: absolute;
      background-color: var(--bg-white);
      width: 100%;
      padding: 1rem;
      border-radius: 1rem;
      .search_item_song{
        margin: 1rem 0;
        width: 100%;
        display: flex;
        // align-items: center;
        .search_item_song_img{
          width: 5rem;
          height: 5rem;
          overflow: hidden;
          border-radius: 0.5rem;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .search_item_name{
          font-size: 1.5rem;
          color: var(--deep-black);
          margin: 0 1rem;
        }
      }
      .search_item_artists{
        margin: 1rem 0;
        width: 100%;
        display: flex;
        // align-items: center;
        .search_item_artists_img{
          width: 5rem;
          height: 5rem;
          overflow: hidden;
          border-radius: 50%;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .search_item_name{
          font-size: 1.5rem;
          color: var(--deep-black);
          margin: 0 1rem;
        }
      }
    }
  }
}

.header_right {
  display: flex;
  .theme {
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;

    .theme__fill,
    .theme__icon {
      transition: 0.3s;
    }

    .theme__fill {
      background-color: var(--bg);
      display: block;
      mix-blend-mode: difference;
      position: fixed;
      inset: 0;
      height: 100%;
      transform: translateX(-100%);
    }

    .theme__icon,
    .theme__toggle {
      z-index: 1;
    }

    .theme__icon,
    .theme__icon-part {
      position: absolute;
    }

    .theme__icon {
      display: block;
      top: 0.8em;
      left: 0.8em;
      width: 1.5em;
      height: 1.5em;
    }

    .theme__icon-part {
      border-radius: 50%;
      box-shadow: 0.4em -0.4em 0 0.5em hsl(0, 0%, 100%) inset;
      top: calc(50% - 0.5em);
      left: calc(50% - 0.5em);
      width: 1em;
      height: 1em;
      transition: box-shadow var(--transDur) ease-in-out,
        opacity var(--transDur) ease-in-out,
        transform var(--transDur) ease-in-out;
      transform: scale(0.5);
    }

    .theme__icon-part ~ .theme__icon-part {
      background-color: hsl(0, 0%, 100%);
      border-radius: 0.05em;
      top: 50%;
      left: calc(50% - 0.05em);
      transform: rotate(0deg) translateY(0.5em);
      transform-origin: 50% 0;
      width: 0.1em;
      height: 0.2em;
    }

    .theme__icon-part:nth-child(3) {
      transform: rotate(45deg) translateY(0.45em);
    }

    .theme__icon-part:nth-child(4) {
      transform: rotate(90deg) translateY(0.45em);
    }

    .theme__icon-part:nth-child(5) {
      transform: rotate(135deg) translateY(0.45em);
    }

    .theme__icon-part:nth-child(6) {
      transform: rotate(180deg) translateY(0.45em);
    }

    .theme__icon-part:nth-child(7) {
      transform: rotate(225deg) translateY(0.45em);
    }

    .theme__icon-part:nth-child(8) {
      transform: rotate(270deg) translateY(0.5em);
    }

    .theme__icon-part:nth-child(9) {
      transform: rotate(315deg) translateY(0.5em);
    }

    .theme__label,
    .theme__toggle,
    .theme__toggle-wrap {
      position: relative;
    }

    .theme__toggle,
    .theme__toggle:before {
      display: block;
    }

    .theme__toggle {
      background-color: #c9d9ff;
      border: 1px solid #3b82bb;
      border-radius: 25% / 50%;
      box-shadow: 0 0 0 0.125em var(--primaryT);
      padding: 0.25em;
      width: 5em;
      height: 2.5em;
      -webkit-appearance: none;
      appearance: none;
      transition: background-color var(--transDur) ease-in-out,
        box-shadow 0.15s ease-in-out, transform var(--transDur) ease-in-out;
    }

    .theme__toggle:before {
      background-color: #3a89ff;
      border-radius: 50%;
      content: "";
      width: 2em;
      height: 2em;
      transition: 0.3s;
      transform: translate(0, -1px);
    }

    .theme__toggle:focus {
      box-shadow: 0 0 0 0.125em var(--primary);
      outline: transparent;
    }

    /* Checked */
    .theme__toggle:checked {
      background-color: hsl(198, 90%, 15%);
    }

    .theme__toggle:checked:before,
    .theme__toggle:checked ~ .theme__icon {
      transform: translate(2.5em, -1px);
    }

    .theme__toggle:checked:before {
      background-color: hsl(198, 90%, 55%);
    }

    .theme__toggle:checked ~ .theme__fill {
      transform: translateX(0);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(1) {
      box-shadow: 0.2em -0.2em 0 0.2em hsl(0, 0%, 100%) inset;
      transform: scale(1);
      top: 0.4em;
      left: 1.3em;
    }

    .theme__toggle:checked
      ~ .theme__icon
      .theme__icon-part
      ~ .theme__icon-part {
      opacity: 0;
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(2) {
      transform: rotate(45deg) translateY(0.8em);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(3) {
      transform: rotate(90deg) translateY(0.8em);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(4) {
      transform: rotate(135deg) translateY(0.8em);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(5) {
      transform: rotate(180deg) translateY(0.8em);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(6) {
      transform: rotate(225deg) translateY(0.8em);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(7) {
      transform: rotate(270deg) translateY(0.8em);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(8) {
      transform: rotate(315deg) translateY(0.8em);
    }

    .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(9) {
      transform: rotate(360deg) translateY(0.8em);
    }

    .theme__toggle-wrap {
      margin: 0 0.75em;
    }

    @supports selector(:focus-visible) {
      .theme__toggle:focus {
        box-shadow: 0 0 0 0.125em var(--primaryT);
      }

      .theme__toggle:focus-visible {
        box-shadow: 0 0 0 0.125em var(--primary);
      }
    }
  }
}
.menu {
  width: 150px;
  display: flex;
  flex-direction: column;
  background: var(--bg-white);
  color: var(--main-color);
  padding: 0.5rem;
  font-size: 1.5rem;
}

.menu-item {
  color: var(--main-color);
  cursor: pointer;
  background-color: transparent;
  text-align: left;
  padding: 1rem;
  border-radius: 0.4rem;
}

.menu-item:hover {
  background-color: #f0dcdc !important;
  border: none !important;
  &.menu-item {
    color: #ab2f76;
  }
}

.menu_btn {
  display: flex;
}

.menu {
  border-radius: 5px;
}

.menu_head {
  width: 200px;
  display: flex;
  flex-direction: column;
  background: #282828;
  color: white;
  padding: 2rem;
  font-size: 1.5rem;
}

.menu_head-item {
  cursor: pointer;
  background-color: transparent;
  text-align: left;
  padding: 1rem;
  border-radius: 0.4rem;
}

.menu_head-item:hover {
  background-color: #3e3e3e !important;
  border: none !important;
}

.menu_head_btn {
  display: flex;
}

.popup-content {
  border-radius: 12px !important;
}

.popup-content_head {
  top: 30px !important;
}
.visible{
  display: none;
}
@media screen and (max-width: 600px) {
  .Header {
    padding: 0;
  }
}
