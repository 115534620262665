.HomeRating_container {
    position: relative;
    &:hover {
        .slick-arrow::before {
            display: block;
        }
    }

    .slick-arrow {
        &::before {
            display: none;
        }

        &.slick-prev {
            position: absolute;
            top: 50%;
            left: -2%;
            z-index: 99;

            &::before {
                font-size: 40px;
            }
        }

        &.slick-next {
            position: absolute;
            top: 50%;
            right: 2%;
            z-index: 99;

            &::before {
                font-size: 40px;
            }
        }
    }
    .ratimg_item {

        

        .rating_item_wrap {
            display: flex;
            box-shadow: 0 2px 10px 0 #00000025;
            margin: 20px 20px 2px 2px;
            padding: 15px;
            gap: 20px;
            background-color: var(--rightsidebar-item-active);
            border-radius: 5px;
            &:hover .img_overlay {
                display: flex !important;
            }
            .ratimg_item_img {
                width: 40%;
                position: relative;
                overflow: hidden;
                &:hover img{
                    transform: scale(1.1);
                }
                img {
                    width: 100%;
                    transition: all 0.3s ease-in-out;

                }
                .img_overlay {
                    position: absolute;
                    background: #00000045;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    transition: all 1s ease;
                    cursor: pointer;
                    .img_overlay_group_btn {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 40px;

                        svg {
                            font-size: 20px;
                            color: #fff;
                        }

                        svg.play_icon {
                            font-size: 40px;
                        }
                    }
                }
            }

            .ratimg_item_content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .ratimg_item_content_name {
                    font-size: 15px;
                    color: var(--text-1);
                }

                .ratimg_item_content_artist {
                    font-size: 12px;
                    color: var(--text-2);
                }

                .ratimg_item_content_b {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .rating_number {
                        opacity: .4;
                        -webkit-text-stroke: 1px var(--color-stroke);
                        font-size: 40px;
                        font-weight: 900;
                        color: transparent;
                        line-height: 1;
                        font-family: "Roboto", sans-serif;
                    }
                    .rating_date{
                        font-size: 14px;
                    color: #868585;

                    }
                }
            }
        }

    }
}