.main_bottom_bar {
    height: 90px;
    width: 100%;
    background: var(--bg-player);
    position: absolute;
    bottom: 0%;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    z-index: 10;

    .rhap_button-clear {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.playlist_btn{
            border-radius: 10px;
            margin-left: 20px;
        }
        &:hover {
            background-color: var(--rightsidebar-hover);

            svg {
                color: #257eff;
            }
        }

        &.rhap_play-pause-button:hover {
            background-color: unset;
        }

        &.btn_more {

            div {
                width: 100%;
                height: 100%;

                div {
                    width: 100%;
                    height: 100%;

                    svg {
                        height: 100%;
                    }
                }
            }

            &:hover {
                svg {
                    fill: #257eff;
                    color: #257eff;
                }
            }
        }



    }

    .player_info {
        width: 30%;
        height: 100%;

        .player_info_ctn {
            display: flex;
            align-items: center;
            gap: 10px;

            .img {
                width: 64px;
                height: 64px;

                img {
                    width: 100%;
                    border-radius: 5px;
                }
            }

            .name {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 83%;
                padding-right: 50px;

                h5 {
                    color: var(--text-1);
                    font-size: 14px;
                }

                .artist {
                    font-size: 13px;
                    height: 18px;
                    width: 140px;
                    padding: 0;
                    overflow: hidden;
                    position: relative;
                    display: inline-block;
                    text-align: center;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: var(--text-2);
                    -webkit-line-clamp: 1;

                }
            }

            .more {
                color: #000;
                display: flex;

                svg {
                    font-size: 16px;
                }
            }

        }
    }

    svg {
        cursor: pointer;
        color: var(--item-player);
        fill: var(--item-player);
    }

    .player_main {
        width: 40%;
        height: 100%;
       
        .rhap_container {
            padding: 0 15px;
            background-color: unset !important;
            box-shadow: unset !important;

            .rhap_time {
                color: var(--text-1);
            }

            .rhap_main-controls-button {
                margin: 0 7px;
            }
        }

        .rhap_stacked-reverse {
            position: relative !important;

            button {
                &.rhap_play-pause-button {
                    svg {
                        font-size: 40px;
                    }
                }

                svg {
                    font-size: 22px;
                }
            }



            .rhap_rewind-button,
            .rhap_forward-button {
                display: none;
            }

            .rhap_container {
                padding: 0 !important;
            }

            .rhap_controls-section {
                justify-content: center !important;

                .rhap_additional-controls,
                .rhap_volume-controls {
                    flex: none;
                }

                .rhap_repeat-button {
                    width: 35px;
                    height: 35px;
                }

                .rhap_volume-controls {
                    svg {
                        font-size: 18px;
                    }
                }

                .rhap_volume-controls {
                    button {
                        width: 35px;
                        height: 35px;
                    }
                }
            }

            .rhap_progress-section {
                .rhap_time {
                    font-size: 13px;
                }

                .rhap_progress-bar {
                    height: 4px;
                }

                .rhap_progress-indicator {
                    width: 12px;
                    height: 12px;
                    margin-left: -10px;
                    top: -4px;
                    background-color: #257eff;
                }

                .rhap_progress-filled {
                    background-color: #257eff;
                }

                .rhap_volume-container {
                    position: absolute !important;
                    right: -60%;
                    width: 100px;
                    top: 30%;
                }

                .rhap_progress-container {
                    &:hover {
                        .rhap_progress-bar {

                            height: 6px;

                            .rhap_progress-indicator {
                                display: block;
                            }
                        }
                    }

                    .rhap_progress-bar {

                        .rhap_progress-indicator {
                            display: none;
                        }
                    }
                }

                .rhap_volume-bar-area {
                    .rhap_volume-indicator {
                        background: #257eff;
                    }
                }
            }
        }

    }

    .player_more {
        display: flex;
        width: 30%;
        justify-content: flex-end;
        // padding-right: 30px;

        .player_more_1 {
            display: flex;
            border-right: 1px solid var(--text-2);
            padding-right: 10px;
        }

        svg {
            width: 20px;
        }
    }

}

.popup-content {
    border-radius: 11px !important;
}

.r_click {
    width: 285px;
    background-color: var(--bg-white);
    color: var(--text-1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    padding: 10px 0;
    position: relative;

    .r_click_head {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 15px;

        &:hover+.submenu-content {
            display: block;
        }

        .r_click_head_img {
            width: 40px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;

            }
        }

        .r_click_head_info {
            .name {
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    color: #257eff;
                }
            }

            .more {
                margin-top: 5px;
                font-size: 12px;
                display: flex;
                gap: 10px;
                color: var(--text-2);

                svg {
                    margin-right: 3px;
                }
            }

        }
    }

    .submenu-content {
        position: absolute;
        right: -80%;
        width: 235px;
        padding: 20px 15px;
        background-color: var(--bg-player);
        border-radius: 10px;
        display: none;

        &:hover {
            display: block;
        }

        .item {
            margin-bottom: 5px;

            h5 {
                color: var(--text-2);
                font-size: 12px;
                text-transform: uppercase;
                line-height: 1.3em;
            }

            .content {
                a {
                    color: var(--text-1);
                    font-size: 15px;
                    line-height: 1.3em;

                    &:hover {
                        color: #257eff;
                    }

                }
            }
        }
    }

    .r_click_navigation {
        background-color: var(--bg-player);
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        text-transform: capitalize;
        // padding: 10px 0;
        margin: 0 15px;
        overflow: hidden;

        .item {
            width: 33.33333%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2px;
            padding: 5px 0;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                background-color: var(--rightsidebar-hover);
            }

            svg {
                width: 20px;
                height: 20px;
                color: var(--text-1);
                fill: var(--text-1);

            }
        }
    }

    .r_click_list {

        .r_click_list_item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 12px 15px;
            font-size: 14px;

            &.add-playlist {
                position: relative;

                &:hover .playlist-content {
                    display: block;
                }

                .playlist-content {
                    background-color: var(--bg-player);
                    width: 250px;
                    min-height: 200px;
                    position: absolute;
                    right: -85%;
                    padding: 15px 0;
                    border-radius: 10px;
                    display: none;

                    .item {
                        display: flex;
                        gap: 10px;
                        padding: 10px;

                        &:hover {
                            background-color: var(--rightsidebar-hover);
                        }
                    }
                }
            }

            &:hover {
                background-color: var(--bg-player);
                cursor: pointer;
            }

            svg {
                width: 18px;
                height: 18px;
                fill: var(--text-1);
            }
        }
    }
}

.Overlay {
    position: fixed;
    inset: 0px;
    background-color: transparent;

}

.Modal {
    width: 285px;
    height: 300px;
    position: absolute;
    bottom: 10%;
    left: 10%;
    transition: all 5s ease-in-out;
}

.Overlay_playlist {
    position: fixed;
    inset: 0px;
    // height: 80vh;
    z-index: 9;
    background-color: var(--bg-player);
    animation: bot-to-top 1s ease-in-out;


    &.active{
        animation: top-to-bot 0.8s ease-in-out;

    }

    @keyframes bot-to-top{
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0%);
        }
    }
    @keyframes top-to-bot{
        0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(100%);
        }
    }


    .playlist_player_bg {
        z-index: -1;
        position: absolute;
        top: 0%;
        opacity: .5;
    }

    .playlist_player_header {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        gap: 10px;

        .header_btn {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: hsl(0deg 0% 100% / 15%);

            &:hover {
                background-color: hsl(0deg 0% 100% / 20%);

            }

            svg {
                font-size: 20px;
                color: #fff;
            }

        }
    }

    .playlist_player_body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: calc(100% - 90px);

        .body {
            display: flex;

            .avt {
                width: 45%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 80%;
                    border-radius: 5px;
                }
            }

            .lyric {
                width: 60%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .scroll-content {
                    font-size: 42px;

                    .item {
                        color: #fff;
                        font-weight: 700;
                        font-size: inherit;
                        line-height: inherit;
                        line-height: 1.2;
                        padding: 15px 0;
                        padding: 20px 0;
                        letter-spacing: -1px;
                        list-style: none;

                        &.over {
                            color: hsla(0, 0%, 100%, .5);
                        }

                        &.active {
                            color: #ffed00;
                        }
                    }
                }
            }
        }
    }
}