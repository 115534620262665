.songpage_main {
  .songpage_list_head {
    display: flex;
  }

  .songpage_mid_head {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 2rem;
    color: #383838;
  }

  .songpage_mid_head h1 {
    font-size: 6rem;
    color: #383838;
  }

  .songpage_mid_head p {
    font-size: 1.5rem;
    color: #383838;
  }

  .songpage_left_head {
    width: 23rem;
    height: 23rem;
  }

  .songpage_left_head img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
  }

  .songpage_info {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }

  .songpage_small_avt {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    object-fit: contain;
  }

  .songpage_small_avt img {
    width: 100%;
    height: 100%;
  }
  .song_control {
    display: flex;
    margin: 2rem 0;
    align-items: center;
    gap: 2rem;
  }
  .menu_btn {
    font-size: 3rem !important ;
  }
  .song_control button {
    background-color: transparent;
    font-size: 5rem;
    margin: 0;
    padding: 0;
    color: var(--main-color);
  }
  .like_btn {
    background-color: transparent;
    font-size: 3rem !important;
    margin: 0;
    padding: 0;
  }

  .Lyrics {
    font-size: 3rem;
    margin: 3rem 0;
    color: var(--main-color);
  }
  .lyrics_line {
    font-size: 2rem;
  }
}
.main_page_container {
  background: linear-gradient(
    -90deg,
    rgb(237, 245, 255) 0%,
    rgb(226, 235, 248) 50%,
    rgb(228, 238, 251) 100%
  );
}
