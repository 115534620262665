.slider-container{
    &:hover{
        .slick-arrow::before{
            display: block;
        }
    }
    .slick-arrow {
        &::before{
            display: none;
        }
        &.slick-prev{
            position: absolute;
            top: 50%;
            left: 2%;
            z-index: 99;
            &::before{
                font-size: 40px;
            }
        }
        &.slick-next{
            position: absolute;
            top: 50%;
            right: 3%;
            z-index: 5;
            &::before{
                font-size: 40px;
            }
        }
    }
    .slider_item{
        img{
            width: 100%;
            padding:  10px;
            border-radius: 20px;
        }
    }
}
@media screen and (max-width:600px) {
    .slick-initialized{
        .slick-slide{
            width: 350px !important;
        }
    } 
    .slick-slider .slick-track, .slick-slider .slick-list{
        height: 20rem;
    }
}