.mod {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .bg_login {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .form-con {
    width: 45rem;
    height: 95%;
    position: absolute;
    right: 5%;
    border-radius: 1rem;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(42, 1, 27, 0.878) 0px 30px 30px -20px;
  }

  input {
    color: white;
  }

  p {
    // color: var(--deep-black);
    color: #257eff;

  }

  /* div{
        color: white;
    } */
  .login_form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .login_form h1 {
    text-align: center;
    margin-bottom: 30px;

    a {
      font-size: 2.5rem;
      margin: 2rem auto;
      color: var(--main-color);

      img {
        width: 150px;
      }
    }
  }

  .input_form_text {
    border: none;
    margin: 1rem;
    padding: 2rem;
    font-size: 1.5rem;
    border-radius: 1rem;
    border-radius: 20px;
    box-shadow: #257eff 0px 7px 4px -3px;
    border-inline: 2px solid transparent;
    color: var(--deep-black);

    &.is-invalid {
      border: 2px solid red;
    }
  }

  .forgot_pass {
    // color: var(--main-color);
    color: #257eff;
    text-decoration: none;
  }

  .input_form_text::placeholder {
    color: rgb(170, 170, 170);
  }

  .input_form_text:focus-visible {
    border: 2px solid var(--main-color);
    outline: none;
  }

  /* signup */
  .signup_form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 1rem;
  }

  .signup_form h1 {
    text-align: center;
    margin-bottom: 30px;

    a {
      font-size: 2.5rem;
      margin: 2rem auto;
      color: var(--main-color);
    }
  }

  /* signup */
  .check_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
    font-size: 13px;
  }

  .remember_login {
    display: flex;
    align-items: center;
    label {
      color: #333;
    }
    .checkmark {
      position: relative;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #fff;
      border-radius: 5px;
    }

    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      border-radius: 5px;
    }

    .container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 20px;
      user-select: none;
      border-radius: 5px;
      box-shadow: 2px 2px 0px rgb(183, 183, 183);
      margin-right: 10px;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      transition: all 0.2s;
      opacity: 1;
      background-image: linear-gradient(
        45deg,
        rgb(100, 61, 219) 0%,
        rgb(82, 203, 255) 100%
      );
    }

    .container input ~ .checkmark {
      transition: all 0.2s;
      opacity: 1;
      box-shadow: 0px 1px 0px 1px rgb(183, 183, 183);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      opacity: 0;
      transition: all 0.2s;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      opacity: 1;
      transition: all 0.2s;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 5px;
      top: 1px;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 0.15em 0.15em 0;
      transform: rotate(45deg);
    }
  }

  .form_btn {
    padding: 1.2rem;
    font-size: 2rem;
    margin: 1rem auto;
    width: 100%;
    background: rgb(37, 126, 255);
    background: linear-gradient(
      90deg,
      rgba(37, 126, 255, 1) 0%,
      rgba(235, 241, 255, 1) 100%
    );
    // background-color: rgb(255 0 131 / 76%);
    transition: all 0.2s ease-in-out;
  }

  .form_btn:hover {
    transform: scale(1.03);
    box-shadow: rgba(125, 197, 255, 0.878) 0px 23px 10px -20px;
  }

  .form_btn:active {
    transform: scale(0.95);
    box-shadow: rgba(122, 175, 255, 0.878) 0px 15px 10px -10px;
  }

  .change_page {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 0.4rem !important;
    font-size: 1.5rem !important;
    // color: var(--main-color);
    color: #333;

    cursor: pointer;
  }

  .change_page:hover {
    color: black !important;
  }

  .change_page_text {
    display: flex;
    font-size: 1.5rem;
    margin: 1rem auto;
  }

  .form_button {
    margin: 0 auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p {
      // color: var(--main-color);
    color: #257eff;

      font-size: 13px;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  .mod {
    background: url("https://th.bing.com/th?id=OIF.C9X%2bsDi0%2fgCUf%2fvy%2btV2gw&rs=1&pid=ImgDetMain");
    height: 100vh;
    .form-con {
      width: 100%;
      right: 0;
      padding: 0;
    }
    .bg_login {
      display: none;
    }
  }
}
