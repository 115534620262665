.Recommended {
  margin: 2rem 0;
  font-size: 4.2rem;
  border-radius: 7px;
  background: linear-gradient(180deg, #57b1e6, #80a9f5);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 16px 20px;
  color: #e9e9e9;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;

  .animation {
    border-radius: 100%;
    animation: ripple 0.9s linear infinite;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1),
      0 0 0 60px rgba(255, 255, 255, 0.15);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
      0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1),
      0 0 0 320px rgba(255, 255, 255, 0.05);
  }
}

.Recommended_1 {
  font-size: 3.1rem;
  color: transparent;
  background-image: linear-gradient(180deg, #119ae9, #d4d4d4, #3549fd);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  letter-spacing: 15px;
  margin-bottom: 28px;
}

.refresh {
  width: 200px;
  height: 50px;
  border-radius: 50px;
  color: var(--white-color);
  background-image: linear-gradient(180deg, #7ec9f5, #97b8f7);
  box-shadow: 0 6px 10px -2px rgba(97, 163, 238, 0.5);
  outline: none;
  cursor: pointer;
  border: none;
  margin: 13px auto;
  font-size: 20px;
  line-height: 48px;
  text-align: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: translateY(3px);
    box-shadow: none;
    color: var(--deep-black);
  }

  svg {
    margin: 0 0 1.5px 10px;
  }
}

.song_img_ctn {
  flex: 2;
}

.root_album {
  flex: 2;
  cursor: pointer;
}

.root_album:hover {
  color: #3549fd;
}

.added_time {
  flex: 2;
}

.foot_r {
  flex: 1;
  display: flex;
}

/* row css */

.list_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  color: var(--text-1);
  width: 100%;
  padding: 10px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--rightsidebar-hover);
  }

  &.list_row_btn:hover {
    background-color: unset !important;
  }

  &.list_row_btn {
    justify-content: center;
  }

  &:hover .img_overlay {
    display: block !important;
  }

  &:nth-of-type(1){
    .number {
      // background-color: #000;padding: 50px;
      -webkit-text-stroke: 2px #5aa5f9 !important;
    }
  }

  &:nth-of-type(2) {
    .number {
      -webkit-text-stroke: 2px #05b890 !important;
    }
  }

  &:nth-of-type(3) {
    .number {
      -webkit-text-stroke: 2px #c12d3c !important;
    }
  }

  .song_img_ctn {
    display: flex;
    align-items: center;

    .row_order {
      width: 3.5rem;
      display: flex;
      justify-content: center;

      .number {
        -webkit-text-stroke: 1.5px var(--text-1);
        opacity: 0.7;
        font-family: Inter;
        font-size: 32px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        width: 32px;
        letter-spacing: normal;
        text-align: center;
        color: transparent;
        font-family: "Roboto", sans-serif;
      }
    }
    .songif {
      .songname {
        color: var(--text-1);
      }
      .songartist {
        color: var(--text-2);
      }
    }
  }
  .root_album,
  .added_time,
  .foot_r {
    color: var(--text-2);
  }

  .song_img {
    width: 4.2rem;
    height: 4.2rem;
    object-fit: contain;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 2rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .img_overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.2705882353);
      width: 100%;
      height: 100%;
      top: 0;
      display: none;
      transition: all 1s ease;

      a {
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        svg {
          color: #fff;
        }
      }
    }
  }
}

.foot {
  display: flex;
  align-items: center;
}

.liked {
  margin: 0 2rem;
}

.hidden_button {
  display: none;
}

.hidden_button .play {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent !important;
}

/* row css */

@media screen and (max-width: 600px) {
  .list_row {
    .added_time {
      display: none;
    }

    .root_album {
      display: none;
    }
  }
}
