.main_page {
  width: 84%;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  position: relative;
  height: fit-content;
  margin-left: 240px;

  /* background-color: #121212; */
  .main_page_container {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 50px 50px;
    border-bottom: #e4e2e2 solid 0.5px;
  //   background: linear-gradient(
  //   180deg,
  //   rgb(191, 215, 250) 0%,
  //   rgba(235, 241, 255, 1) 42%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  background: var(--background-color) !important;
  }

  .for_you {
    h1 {
      margin: 30px 0;

      color: var(--deep-black);;
      font-size: 23px;
      text-transform: capitalize;
    }

    .radio-inputs {
      display: flex;
      // justify-content: center;
      align-items: center;
      max-width: 350px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin: 20px 0;
    }

    .radio-inputs > * {
      margin: 6px;
    }

    .radio-input:checked + .radio-tile {
      border-color: #3887ff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      color: var(--white-color);
    }

    .radio-input:checked + .radio-tile:before {
      transform: scale(1);
      opacity: 1;
      background-color: #afc7ff;
      border-color: #cc39a4;
    }

    .radio-input:checked + .radio-tile .radio-icon svg {
      fill: var(--white-color);
    }

    .radio-input:checked + .radio-tile .radio-label {
      color: var(--white-color);
    }

    .radio-input:focus + .radio-tile {
      background-color: #afc7ff;
      border-color: var(--white-color);
      // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
    }

    .radio-input:checked + .radio-tile {
      background-color: var(--bg-radio);
      border-color: var(--white-color);
    }

    .radio-input:focus + .radio-tile:before {
      transform: scale(1);
      opacity: 1;
    }

    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 30px;
      border-radius: 20px;
      // border: 2px solid #b5bfd9;
      background-color: #fff;
      color: #000;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      transition: 0.15s ease;
      cursor: pointer;
      position: relative;
      font-size: 14px;
    }

    .radio-input:hover {
      border-color: #3887ff;
    }

    .radio-tile:hover:before {
      transform: scale(1);
      opacity: 1;
    }

    .radio-label {
      color: #707070;
      transition: 0.375s ease;
      text-align: center;
      font-size: 13px;
    }

    .radio-input {
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(100%);
      clip-path: inset(100%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
}

.ratings,
.list_card {
  h1 {
    margin: 30px 0 10px;

    color: var(--deep-black);;
    font-size: 23px;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 600px) {
  .main_page {
    width: 100%;
    margin-left: 0 !important;
    .main_page_container {
      padding: 1rem;
    }
  }
}
