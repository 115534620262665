.rightsidebar {
  left: 0;
  // background-image: linear-gradient(to top, #FDCEDF, #F2BED1, #F8E8EE, #F9F5F6, #F9F5F6);
  background-color: var(--rigthsidebar-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16%;
  height: 88vh;
  /* box-shadow: 20px 20px 50px rgba(0,0,0, 0.5); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  .logo-name img {
    width: 110px;
    display: block;
    margin: 0 auto;
  }
  .logo-name {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    border-radius: 10px;
    justify-content: center;
    margin: 0px 4px 4px 4px;
    // border-bottom: 2px solid var(--deep-black);;
  }
  .rs_bottom_bar {
    font-size: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .list_nav {
      padding-bottom: 20px;
      border-bottom: 1px solid;
    }
  }
  .rs_top_bar a {
    text-decoration: none;
    color: white;
    text-align: justify;
    /* margin: 15px; */
    text-align: center;
  }
  .btn {
    background-color: transparent !important;
  }

  .btn_l {
    border-radius: 20px;
    font-size: 1.5rem;
    padding: 0.4em 1em !important;
    margin: 0 5px;
  }
  .bottom_list {
    height: 53vh;
  }
  .bottom_list_container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .icon_list_nav_item {
    margin-right: 8px;
    width: 35px;
  }
  .icon_list_nav_item img {
    width: 20px;
  }
  .icon_list_nav_item_svg {
    width: 20px;
    height: 20px;
    fill: var(--deep-black);
  }
  /* #ee1169 */
  .list_nav_item svg {
    color: var(--deep-black);
    transition: opacity 100ms ease-in-out;
  }
  .list_nav_item span {
    font-size: 16px;
    color: var(--deep-black);
  }
  .list_nav_item.active {
    background: var(--rightsidebar-item-active);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    box-shadow: inset 1px 1px 0.12px rgba(255, 255, 255, 0.4),
      1px 1px 3px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    // border: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.3s ease-in-out;

    & span {
      color: var(--main-color);
    }
    & svg {
      fill: var(--main-color);
    }
  }
  .list_nav_item:hover {
    background: var(--rightsidebar-hover);
    // backdrop-filter: blur(4px);
    // -webkit-backdrop-filter: blur(4px);
    transform: scale(105%);
    transition: all 0.2s ease-in-out;
  }
  .list_nav_item:hover .icon_list_nav_item_svg {
    width: 22px;
    height: 22px;
    fill: var(--main-color);
    animation: beatingHeart 1.2s infinite;
  }
  .list_nav_item:hover span {
    color: var(--main-color);
  }
  @keyframes beatingHeart {
    0% {
      transform: scale(1);
    }

    15% {
      transform: scale(1.15);
    }

    30% {
      transform: scale(1);
    }

    45% {
      transform: scale(1.15);
    }

    60% {
      transform: scale(1);
    }
  }
  .list_nav_item {
    --color: var(--white-color) !important;
    position: relative;
    overflow: hidden;
    transition: color 0.5s;
    z-index: 1;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    /* transition: all .3s; */
    z-index: 1;
    border-radius: 10px;
    text-decoration: none;
  }

  .list_nav_item:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
  }

  .list_nav_item:hover {
    color: var(--white-color);
  }

  .list_nav_item:before {
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }

  //    .list_nav_item:hover:before {
  //     top: -30px;
  //     left: -30px;
  //    }

  .list_nav_item:active:before {
    background: var(--white-color);
    transition: background 0s;
  }

  .nav_bot_playlist {
    margin-top: 30px;
    .playlist_item {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 5px 10px;
      border-radius: 10px;
      color: var(--footer-text);
      &:hover {
        background-color: var(--rightsidebar-hover);
        cursor: pointer;
      }
      .playlist_item_img {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
        }
      }
      .playlist_item_content {
        .content_name {
          font-size: 20px;
        }
        .content_cate {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .rightsidebar {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-items: center;
    height: 7rem;
    z-index: 9;
    background-color: transparent !important;
    .bg-body-tertiary {
      .container {
        .navbar-collapse {
          background-color: rgba(255, 255, 255, 0.619);
        }
      }
    }
    .puffed {
      display: none;
    }
    .nav_bot_playlist {
      display: none;
    }
    .logo-name {
      display: none !important;
    }
    .rs_bottom_bar {
      .list_nav {
        display: flex;
        height: 100%;
      }
    }
    .list_nav_item {
      span {
        display: none !important;
      }
      .icon_list_nav_item {
        text-align: center;
      }
      margin-right: 0 !important;
    }
    .navbar-light {
      height: 100%;
      .container {
        height: 100%;
      }
    }
    .icon_list_nav_item {
      margin: 0;
    }
    .rs_bottom_bar {
      .list_nav {
        padding: 0;
      }
    }
  }
}
